import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { styles } from './styles';
import logo from '../../assets/logo.png';
import { Divider } from '../../components/divider/Divider';
import { PureTextBlock } from '../../components/pureTextBlock/PureTextBlock';
import { Footer } from '../../components/footer/Footer';

import { en, da, languageStringsType } from '../../translations/strings';
import { ApiResponse, Fisherman, Product } from '../../types/product';

type routerParams = {
  language?: string;
  device?: string;
  core?: string;
  menu?: string;
  place?: string;
};

export const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [menuData, setMenuData] = React.useState<ApiResponse>();
  const [language, setLanguage] = React.useState<string>('da');
  const [restaurantId, setRestaurantId] = React.useState<string>('');
  const [localStrings, setLocalStrings] = React.useState<languageStringsType>({ ...en });

  const params: routerParams = useParams();

  if (params.language && params.language !== language) {
    setLanguage(params.language);
  }

  if (params.place && params.place !== restaurantId) {
    setRestaurantId(params.place);
  }

  const baseUrl: string = `https://api.bluelobster.app/${language}/core/menu/${restaurantId}`;

  const fetchMenu = async (): Promise<ApiResponse | undefined> => {
    try {
      return (await axios.get(baseUrl)).data;
    } catch {
      console.error('Fetch failed');
    }
  };

  React.useEffect(() => {
    fetchMenu().then((response) => {
      setMenuData(response);
      setIsLoading(false);
      document.title = `Blue Lobster - ${response?.company.name || ''}`;
      window.gtag('event', 'page_view', {
        restaurant: response?.company.name,
        restaurantId,
      });
      window.gtag('event', response?.company.name || 'no company', {
        restaurant: response?.company.name,
        restaurantId,
      });
    });
    if (!menuData) {
      return;
    }
  }, []);

  React.useEffect(() => {
    if (params.language === 'en') {
      setLocalStrings({ ...en });
    }
    if (params.language === 'da') {
      setLocalStrings({ ...da });
    }
  }, [params.language]);

  React.useEffect(() => {
    if (!menuData?.company.name) {
      return;
    }
    if (restaurantId) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  return (
    <div style={styles.appContainer}>
      <img src={logo} alt='blue lobster logo' style={styles.logoTop} />
      {isLoading === false ? (
        <>
          <div style={styles.textContainer}>
            <h1 style={styles.h1}>{localStrings.header.toUpperCase()}</h1>
            <div style={styles.subHeader}>{menuData?.company?.name}</div>
          </div>
          {menuData?.products[0] ? (
            <div style={styles.fishInfoContainer}>
              {menuData?.products?.map((item: Product) => (
                <>
                  <h1 style={styles.h1}>{item.name.toUpperCase()}</h1>
                  {item?.fishermen?.map((fisherman: Fisherman) => {
                    return (
                      <>
                        <div style={styles.subHeader}>
                          {localStrings.caughtBy} {fisherman.name} {localStrings.in} {fisherman.landing_location}
                        </div>
                        <div style={styles.detailsText}>
                          {fisherman.name} {localStrings.uses} {fisherman.fishing_method.toLowerCase()} {localStrings.fishingMethod}.{' '}
                          {localStrings.boatName} {fisherman.boat_name} {localStrings.boatLength} {fisherman.boat_length} {localStrings.long}.
                          <div>{fisherman.boat_image !== '' ? <img src={fisherman.boat_image} alt='boat' style={styles.boatImage} /> : null}</div>
                        </div>
                      </>
                    );
                  })}
                  <Divider />
                </>
              ))}
            </div>
          ) : (
            <PureTextBlock text={localStrings.noFish} />
          )}

          <div style={styles.subHeader}>{localStrings.didYouKnow}</div>
          <PureTextBlock text={localStrings.harborFacts} />
          <PureTextBlock text={localStrings.fishingFacts} />
        </>
      ) : (
        <div>Loading</div>
      )}
      <Footer language={language} />
    </div>
  );
};
