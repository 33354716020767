export const styles = {
  appContainer: {
    fontFamily: 'Chivo, sans-serif',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as 'column',
  },
  logoContainer: {},
  logoTop: {
    width: '70%',
    paddingTop: '5%',
  },

  textContainer: {
    paddingTop: 40,
    alignItems: 'center',
  },

  h1: {
    fontWeight: 800,
    textAlign: 'center' as 'center',
    fontSize: 30,
    marginBottom: 5,
  },

  subHeader: {
    fontWeight: 400,
    textAlign: 'center' as 'center',
    fontSize: 24,
    marginTop: 0,
  },

  fishInfoContainer: {
    paddingTop: '8%',
  },

  detailsText: {
    fontWeight: 300,
    textAlign: 'center' as 'center',
    fontSize: 16,
    marginTop: 10,
  },

  boatImage: {
    paddingTop: '5%',
    paddingBottom: '5%',
    width: '80%',
  },
};
