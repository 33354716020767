export const Divider = () => {
  return (
    <hr
      style={{
        borderTop: '1px solid #bbb',
        opacity: '20%',
        width: '60%',
        marginBottom: '7%',
        marginTop: '5%',
      }}></hr>
  );
};
