export const styles = {
  textContainer: {
    fontFamily: 'Chivo, sans-serif',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    lineHeight: 1.5,
    fontSize: 16,
    textAlign: 'center' as 'center',
    paddingBottom: 10,
    paddingTop: 10,
    marginLeft: 10,
    marginRight: 10,
  },
};
