import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { App } from '../screens/root/App';

export const Navigation = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route path='/:language/:core/:menu/:place'>
          <App />
        </Route>
        <Route path='/'>
          <App />
        </Route>
      </Switch>
    </Router>
  );
};
