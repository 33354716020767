import React from 'react';
import { styles } from './styles';

import appStoreBlackEnglish from '../../assets/appStoreBlackEnglish.svg';
import googlePlayBadgeEn from '../../assets/googlePlayBadgeEn.png';

import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { en, da, languageStringsType } from '../../translations/strings';

type FooterProps = {
  language: string;
};

export const Footer = ({ language }: FooterProps) => {
  const [localStrings, setLocalStrings] = React.useState<languageStringsType>({ ...en });

  React.useEffect(() => {
    if (language === 'en') {
      setLocalStrings({ ...en });
    }
    if (language === 'da') {
      setLocalStrings({ ...da });
    }
  }, [language]);

  return (
    <div>
      <h1 style={styles.h1}>{localStrings.footer?.questions}</h1>
      <p style={styles.p}>
        {localStrings.footer?.findAnswers}
        <a
          href={
            language === 'da'
              ? 'https://bluelobster.app/da/private-forbrugere/faq/'
              : 'https://bluelobster.app/private/faq/'
          }
          target='_blank'
          rel='noreferrer noopener'>
          {localStrings.footer?.ourFAQ}
        </a>
      </p>
      <h1 style={styles.h1}>{localStrings.footer.downloadApp}</h1>
      <div style={styles.downloadAppContainer}>
        <a
          href='https://apps.apple.com/dk/app/blue-lobster/id1463286819?itsct=apps_box_link&itscg=30200'
          target='_blank'
          rel='noreferrer noopener'>
          <img src={appStoreBlackEnglish} alt='Download at the app store' style={styles.downloadAppApple} />{' '}
        </a>
        <a
          href='https://play.google.com/store/apps/details?id=org.bluelobster.android'
          target='_blank'
          rel='noreferrer noopener'>
          <img src={googlePlayBadgeEn} alt='Download at the google play store' style={styles.downloadApp} />
        </a>
      </div>
    </div>
  );
};
